import { useState, useEffect } from 'react';
import styles from './profileButton.module.css';
import { FaRegUserCircle } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BsChatDots } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserProfile } from 'project-name/Redux/features/userProfile';
import { RootState, AppDispatch } from 'project-name/Redux/Store';
import users from "project-name/assets/users.png";
import apiClient from 'project-name/api/apiClient';

const ProfileButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showProfile, setShowProfile] = useState(false);
  const profileDetail = useSelector((state: RootState) => state.userProfile.data);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const handleNotificationClick = () => {
    setShowProfile(!showProfile);
  };

  const handleCancelClick = () => {
    setShowProfile(false);
  };

  const handleLogout = async () => {
    try {
      const response = await apiClient.get('/api/logout', { withCredentials: true });
      // console.log(response.data); // Optional: log logout success
      navigate('/login'); // Redirect to login page after logout
    } catch (error: any) {
      console.error(error.response?.data || 'Something went wrong');
    }
  };

  return (
    <div>
      <div className={styles.navbarItemsTools} onClick={handleNotificationClick}>
        <img src={users} style={{ width: "60px" }} alt="Profile" />
      </div>

      {showProfile && (
        <div className={styles.profileDropdown}>
          <div className={styles.profileDiv}>
            <div><h4>{profileDetail?.email}</h4></div>
            <div className={styles.cancelIcon} onClick={handleCancelClick}>X</div>
          </div>

          <Link to='/edit/admin-profile'>
            <div className={styles.profileContent}>
              <div><FaRegUserCircle className={styles.profileIcon} /></div>
              <div><span className={styles.profileName}>Update Profile</span></div>
            </div>
          </Link>

          <div className={styles.profileContent} onClick={handleLogout}>
            <div><RiLogoutCircleRLine className={styles.profileIcon} /></div>
            <div><span className={styles.profileName}>Logout</span></div>
          </div>

          <div className={styles.profileContent}>
            <div><BsChatDots className={styles.profileIcon} /></div>
            <div><span className={styles.profileName}>Helpdesk</span></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
