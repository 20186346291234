import React, { useEffect, useState } from 'react';
import styles from './OnboardingSlider.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

import { useSelector  } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import JSZip from "jszip";
import { saveAs } from "file-saver";

import PDFview from 'project-name/PDFpopup';
import { useLocation } from 'react-router-dom';
import apiClient from 'project-name/api/apiClient';


interface DocumentDetails {
  [key: string]: {
    document_name: string;
    file_encoded: string;
    user_id: string;
  }[];
}
interface DocumentsSectionProps {
  documentDetails: DocumentDetails;
  handleClickOpen: (docType: string, documents: any[]) => void;
  open: boolean;
  handleClose: () => void;
  textareaValue: string;
  setTextareaValue: (value: string) => void;
  currentDocType: string | null;
  selectedDocument: string;
  setSelectedDocument: (value: string) => void;
}

const DocumentsSection: React.FC <DocumentsSectionProps> = () => {

  
  // Function to download a single document in a ZIP file
const downloadIndividualFileAsZip = async(doc_type: string) =>{
  const folderName = doc_type;
  const doc = documentDetails[doc_type]
    const zip = new JSZip();

    // Iterate over each category in the documents object
    for (const [files] of Object.entries(documentDetails[doc_type])) {
      const folder = zip.folder(folderName); // Create a folder for each document type
      console.log("ddddd",folderName, files)

      files.forEach(file => {
        const decodedFile = atob(file.file_encoded); // Decode the Base64 string
        const fileBlob = new Blob([decodedFile], { type: "application/pdf" });
        folder.file(`${file.document_name}.pdf`, fileBlob); // Add file to the folder
      });
    }
  
    // Generate the ZIP file and trigger the download
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "documents.zip");
  }
  
  const handleDownloadAll = async () => {
    console.log('Download all documents',documentDetails);
    const zip = new JSZip();

    // Iterate over each category in the documents object
    for (const [folderName, files] of Object.entries(documentDetails)) {
      const folder = zip.folder(folderName); // Create a folder for each document type
  
      files.forEach(file => {
        const decodedFile = atob(file.file_encoded); // Decode the Base64 string
        const fileBlob = new Blob([decodedFile], { type: "application/pdf" });
        folder.file(`${file.document_name}.pdf`, fileBlob); // Add file to the folder
      });
    }
  
    // Generate the ZIP file and trigger the download
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "documents.zip");
  };

  let educationTypes = useSelector((state: RootState) => state.education.EducationList);
  
  const documents = useSelector((state: RootState) => state.candidateDocuments.documents);
  if(educationTypes.length===0){
    educationTypes = localStorage.getItem("matchingEducationTypes") ? JSON.parse(localStorage.getItem("matchingEducationTypes") || "") : [];
  }
  const [open, setOpen] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(location.state.user_id ||  0);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [currentDocType, setCurrentDocType] = useState('');
  const [currentDocDetails, setCurrentDocDetails] = useState(null);
  const [documentDetails, setDocumentDetails] = useState<any[]>([]);

  useEffect(()=>{
if(documents.length>0){
  const groupedByDocType = documents.reduce((acc: any, currentItem: any) => {
    const { document_type } = currentItem;
    
    if (!acc[document_type]) {
        acc[document_type] = [];
    }
    
    acc[document_type].push(currentItem);
    
    return acc;
  }, {});
  setDocumentDetails(groupedByDocType);
}
  },[documents])


 

   const handleClickOpen = (docType:any,docDetails:any) => {
    setCurrentDocType(docType);
    setOpen(true);
    setCurrentDocDetails(docDetails);
  };

  const handleClose = () => {
    setOpen(false);
    setTextareaValue('');
    setSelectedDocument('');
  };

  const handleReject = async() => {
    const status = 'rejected';
    const payload = {
      doc_id: selectedDocument,
      status: status,
    };
   

    try {
      const response = await apiClient.post(
        `/api/admin/${user_id}/update-document-status`,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
    

      if (!response) {
        throw new Error('Network response was not ok');
      }
    
      const data = await response.data;
      console.log('API Response:', data);
    } catch (error) {
      console.error('Error:', error);
    }

    handleClose();
  };

  return (
    <section className={styles.mainAccord}>
      {documents.length === 0 && (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"} padding={"5px"}>
          <Typography>No documents available</Typography>
        </Box>
      )}
      {/* First Accordion */}
      {/* <div className={styles.subAccord}>
        <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(0)}
        >
          <p>Education documents</p>
          <b className={styles.statusDiv}>
            <p style={{ color: '#877A0A' }}>Pending</p>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <div className={styles.panel} style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
        
          <div className={styles.mAttachDiv}>
           
          <div className={styles.subAccord}>
        <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(0)}
          aria-expanded={activeIndex === 0}
        >
          <p>10th documents</p>
          <b className={styles.statusDiv}>
          {educationTypes.find((doc) => doc.education_type === '10th') ? (         
           <CompletedIcon/>
        ) : (
           <InprogressIcon/>
        )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        </div> */}

        
            {/* <div className={styles.attachDiv1}>
              <div className={styles.subAttachDiv1} onClick={handleOpen}>
                <div className={styles.iconDiv}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                  {attachmentIconPath}
                  </svg>{' '}
                  <p>10thmarksheetShrikantra...</p>
                </div>

                <div className={styles.docimgDiv}>
                  <img src={doc} alt="" style={{ width: '280px', height: '150px' }} />
                </div>
              </div>
              <div className={styles.subAttachDiv1} onClick={handleOpen}>
                <div className={styles.iconDiv}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                  {attachmentIconPath}
                  </svg>{' '}
                  <p>10thmarksh...</p>
                </div>

                <div className={styles.docimgDiv}>
                  <img src={doc} alt="" style={{ width: '280px', height: '150px' }} />
                </div>
              </div>
            </div> */}

            {/* buttons div */}
            {/* <div className={styles.btn1}>
              <Button variant="outlined" data-testid="ask-to-resubmit-button1">Ask to submit</Button>
              <Button type="submit" variant="contained" color="primary">
                Download all
              </Button>
            </div> */}
          {/* </div> */}
          {/* <div className={styles.subAccord}> */}
        {/* <button
          className={`${styles.accordion} ${activeIndex === 1 ? styles.active : ''}`}
          onClick={() => toggleAccordion(1)}
        >
          <p>Current address details</p>
          <b className={styles.statusDiv}>
            <p style={{ color: '#877A0A' }}>Pending</p>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 1 ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </b>
        </button> */}
        {/* <div className={styles.panel} style={{ display: activeIndex === 1 ? 'block' : 'none' }}> */}
          {/* Panel content */}
          {/* <div className={styles.panelDiv} >
            <div>
              <div className={styles.formGroup2}>
                <label htmlFor="form-input1" className={styles.label}>
                  House/Flat No. Street/Colony Area
                </label>
                <div>
                  <div className={styles.textField}>
                    <textarea
                      id="form-input1"
                      placeholder="enter details"
                      rows={10}
                      cols={52}
                      // Set the number of rows as per your requirement
                      className={styles.descInput}
                    />
                  </div>
                </div>
              </div> */}

              {/* <FormField
                type=''
              label="Pin code"
              name="pin_code"
              placeholder="Enter pin code"
              cssInput2={styles.label} // Ensure this is correctly passed as per your component setup
            />

<FormSelect
                label="Function"
                id="function-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />
            </div>

            <div>
              <FormSelect
                label="Country"
                id="country-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="City"
                id="city-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="State/Union Territory"
                id="state-ut-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="Is your Bangalore Address same as Current Address?"
                id="same-address-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />
            </div>
            <div className={styles.attachmainDiv}>
              <label htmlFor="form-input2" className={styles.label}>
                Attachment
              </label>

              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />

              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />
            </div>
          </div> */}

          {/* <div className={styles.btn} >
            <Button variant="outlined">Update for candidate</Button>
            <Button variant="outlined" data-testid="ask-to-resubmit-button">Ask to resubmit</Button>
            <Button type="submit" variant="contained" color="primary">
              Approve document
            </Button>
          </div>
        </div>
      </div>
        </div>
      </div> */}

      {/* Second Accordion */}
      {/* <div className={styles.subAccord}>
        <button
          className={`${styles.accordion} ${activeIndex === 1 ? styles.active : ''}`}
          onClick={() => toggleAccordion(1)}
        >
          <p>Current address details</p>
          <b className={styles.statusDiv}>
            <p style={{ color: '#877A0A' }}>Pending</p>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 1 ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </b>
        </button> */}
        {/* <div className={styles.panel} style={{ display: activeIndex === 1 ? 'block' : 'none' }}> */}
          {/* Panel content */}
          {/* <div className={styles.panelDiv} >
            <div>
              <div className={styles.formGroup2}>
                <label htmlFor="form-input1" className={styles.label}>
                  House/Flat No. Street/Colony Area
                </label>
                <div>
                  <div className={styles.textField}>
                    <textarea
                      id="form-input1"
                      placeholder="enter details"
                      rows={10}
                      cols={52}
                      // Set the number of rows as per your requirement
                      className={styles.descInput}
                    />
                  </div>
                </div>
              </div> */}

              {/* <FormField
                type=''
              label="Pin code"
              name="pin_code"
              placeholder="Enter pin code"
              cssInput2={styles.label} // Ensure this is correctly passed as per your component setup
            />

<FormSelect
                label="Function"
                id="function-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />
            </div>

            <div>
              <FormSelect
                label="Country"
                id="country-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="City"
                id="city-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="State/Union Territory"
                id="state-ut-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />

              <FormSelect
                label="Is your Bangalore Address same as Current Address?"
                id="same-address-select"
                options={selectOptions}
                selectCss2={styles.label}
                selectCss3={styles.formControl}
              />
            </div>
            <div className={styles.attachmainDiv}>
              <label htmlFor="form-input2" className={styles.label}>
                Attachment
              </label>

              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />

              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />
            </div>
          </div> */}

          {/* <div className={styles.btn} >
            <Button variant="outlined">Update for candidate</Button>
            <Button variant="outlined" data-testid="ask-to-resubmit-button">Ask to resubmit</Button>
            <Button type="submit" variant="contained" color="primary">
              Approve document
            </Button>
          </div>
        </div>
      </div> */}
      {Object.keys(documentDetails).map((docType: any) => (
        <Accordion key={docType}>
          <AccordionSummary aria-controls={`panel${docType}-content`} id={`panel${docType}-header`}>
            <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
              <Typography>{docType?.toUpperCase()} DOCUMENTS</Typography>
              <ExpandMoreIcon
                className={styles.moreIcon}
                // style={{ transform: expanded === `panel${docType}` ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} width={"85vw"}>
              {documentDetails[docType].map((doc:any, index: number) => (
                    
                      <Grid item xs={12} sm={4} key={`${doc}-${index}`}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                          <Box style={{ width: '280px', height: '280px' }} >
                          {doc.document_name === 'Profile Photo' ? (
                            <>
                              <img 
                                src={`data:image/jpeg;base64,${doc.file_encoded}`} 
                                alt="Profile Photo" 
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                              />
                              <a 
                                href={`data:image/jpeg;base64,${doc.file_encoded}`} 
                                download={`${doc.user_id}_${doc.document_name}.jpg`}
                                style={{ marginTop: '10px', textDecoration: 'none', color: 'blue' }}
                              >
                                Download
                              </a>
                            </>
                          ) : (
                            <PDFview 
                              onClose={function (): void {
                                throw new Error('Function not implemented.');
                              }} 
                              base64={doc.file_encoded} 
                              fileName={`${doc.user_id}_${doc.document_name}`}
                            />
                          )}
                          </Box>
                            <Typography>{doc.document_name?.toUpperCase()}</Typography>
                        </Box>
                      </Grid>
                    )

              )}
              <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} padding={"5px"}>
                <Button type="submit" variant="contained" color="primary" onClick={() => downloadIndividualFileAsZip(docType)}>
                  Download Documents
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => handleClickOpen(docType,documentDetails[docType])}>
                  Reject Document
                </Button>
                </Box>
            </Grid>
            {/* <div className={styles.btn}>
              <Button variant="outlined">Update for candidate</Button>
              <Button variant="outlined">Ask to resubmit</Button>
              <Button type="submit" variant="contained" color="primary">
                Approve document
              </Button>
            </div> */}
          </AccordionDetails>
        </Accordion>
      ))}
      <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reject Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason for rejecting the document.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={textareaValue}
            onChange={(e) => setTextareaValue(e.target.value)}
          />
         {(currentDocType !==null) && (
        <Select
          value={selectedDocument}
          onChange={(e) => setSelectedDocument(e.target.value)}
          displayEmpty
          fullWidth
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="" disabled>Select Document</MenuItem>
          {documentDetails[currentDocType as keyof typeof documentDetails]?.map((doc:any, index:any) => (
            <MenuItem key={index} value={doc.id}>{doc.document_name}</MenuItem>
          ))}
        </Select>
    )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReject} color="secondary">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      </>
      {documents.length !== 0 && (
          <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} padding={"5px"}>
          <Button type="submit" variant="contained" color="primary"  onClick={handleDownloadAll}>
            Download all documents
          </Button>
        </Box>
        )}
    </section>
  );
};

export default DocumentsSection;
