import React from 'react';
import styles from './RecruiterTable.module.css';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
 // Custom CSS for styling
import Pagination from './Pagination';
import { paginate } from 'project-name/util/paginationUtils'; 
import { usePagination } from 'project-name/util/tableUtils'; 
import profilesData from '../../../../../Data/admin/recruiterData.json'; // Import your JSON data file
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';

interface SavedProfile {
  name: string;
  email: string;
  candidates: string;
  recruiterrole: string[];
}

interface RecruiterTableProps {
  headers: string[];
}

const RecruiterTable: React.FC<RecruiterTableProps> = ({ headers }) => {
  const savedProfiles: SavedProfile[] = profilesData;
  const itemsPerPage = 5; // Number of items to display per page
  

  

 
  const { currentPage, totalPages, handlePageChange } = usePagination(savedProfiles.length, itemsPerPage);
  const getButtonStyle = (role: string) => {
    switch (role) {
      case 'Onboarding agent':
        return { backgroundColor: '#ebdbbd', color: 'black' }; // Green background for Onboarding agent
      case 'Onboarding admin':
        return { backgroundColor: '#c6e0c1', color: 'black' }; // Red background for Onboarding admin
      default:
        return { backgroundColor: '#3f51b5', color: 'white' }; // Default background color
    }
  };

  return (
    <div className={styles.tableContainer}>

 
    <TableContainer component={Paper}>
      <Table className={styles.candidateDetailsbox}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} style={{ fontWeight: 'bold', color: '#fff', backgroundColor: 'black' }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginate(savedProfiles, currentPage, itemsPerPage).map((profile, index) => (
            <React.Fragment key={index}>
              <TableRow sx={{ py: 5 ,    borderColor: 'transparent', cursor: 'pointer', transition: 'all 0.3s',
                  padding: '16px', boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 8px 8px rgba(0, 0, 0, 0.2)',

                  },}}>
                <TableCell sx={{ py: 3, my: 2  , borderColor: 'transparent',}}>{profile.name}</TableCell>
                <TableCell sx={{ mt: 2 }}>{profile.email}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{profile.candidates}</TableCell>
                <TableCell>
                  {profile.recruiterrole
                    .filter((role) => role) // Filter out any falsy or empty roles
                    .map((role, roleIndex) => (
                      <Button key={roleIndex} variant="contained" color="primary"  style={{ ...getButtonStyle(role), marginRight: '5px'  ,  fontWeight:500}}>
                        {role}
                      </Button>
                    ))}
                </TableCell>
                <TableCell>
                  < BorderColorOutlinedIcon  />
                 < DeleteOutlineOutlinedIcon  sx={{marginLeft:2}}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} sx={{ py: 0 }}>
                  <div style={{ height: '10px' }}></div>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  



      <div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

const RecruiterSection: React.FC = () => {
  const headers = ['Recruiter Name', 'Email', 'Candidates', 'Recruiter Role', 'Action'];

  return (
    <div style={{ padding: '20px' }}>
      <RecruiterTable headers={headers} />
    </div>
  );
};

export default RecruiterSection;
